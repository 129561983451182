<template>
  <v-avatar tile size="40" class="mr-3">
    <span class="id-icon">id</span>
  </v-avatar>
</template>

<script>
  /**
   * @project idenfit
   * @developer Halil Kılıçarslan
   * @description IdIcon Component Logic
   * @date 17.03.2020
   */
  export default {
    name: "IdIcon"
  };
</script>

<style scoped>
  .id-icon {
    width: 33px;
    height: 33px;
    background: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border-radius: 3px;
    font-family: "Paytone One", sans-serif;
  }
</style>
